// replaced by 'turbo_with_prefetch'
// import initPrefetchOnHoverintent from './prefetch_on_hoverintent'
import initTrackEvents from './track_events'
import initHasClickableUrls from './has_clickable_urls'
import initHasAutocomplete from './has_autocomplete'
// root specific
// import initBulmaUX from './bulma_ux'

// Things to be executed when the page loads
const onDocumentReadyLoad = () => {
  // @ts-ignore
  // gtag('config', 'UA-10949806-18', { 'page_path': window.location.pathname, 'anonymize_ip': true })
  // @ts-ignore
  gtag('config', 'G-735XEXJ594', { 'page_path': window.location.pathname, 'anonymize_ip': true })

  // initPrefetchOnHoverintent()
  initTrackEvents()
  initHasClickableUrls()
  initHasAutocomplete()
  // root specific
  // initBulmaUX()
}

document.addEventListener('turbo:load', onDocumentReadyLoad)
