/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Turbo need to be loaded before @rails/ujs
import '../turbo_with_prefetch'

// to be deleted: 2024-04-16
// require("@rails/ujs").start()
import Rails from "@rails/ujs"
Rails.start()

// Some global utilities
function isEmpty(value) {
  return (value == null || (typeof value === "string" && value.trim().length === 0) || value.length === 0);
}
window.isEmpty = isEmpty

// Alpine-turbo-drive-adapter allows you to add support for Alpine.js to your Turbolinks/Turbo powered apps.
// It handles events to properly clean up the DOM from Alpine generated code when navigating between pages.
// import 'alpinejs'
import 'alpine-turbo-drive-adapter'
import Alpine from 'alpinejs'

import intersect from '@alpinejs/intersect'
Alpine.plugin(intersect)

import anchor from '@alpinejs/anchor'
Alpine.plugin(anchor)

// Custom local plugins
import timer from '../alpine/x-timer'
Alpine.plugin(timer)

window.Alpine = Alpine
Alpine.start()

// to be deleted: 2024-06-03
// Stimulus
// import { Application } from "@hotwired/stimulus"
// import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"

// const application = Application.start()
// // I cannot make Stimulus play correctly with TypeScript
// const context = require.context("../controllers", true, /\.js$/)
// application.load(definitionsFromContext(context))

import '../on_document_ready_root'

// import '../styles/root_styles.scss'
