import autoComplete from "@tarekraafat/autocomplete.js";

const DEBOUNCE_MS = 200;

export default function initHasAutocomplete() {
  document.querySelectorAll('.has-autocomplete').forEach((el) => {
    let config = {
      debounce: DEBOUNCE_MS,
      selector: () => el,
      name: 'autocomplete-component',
      data: {
        src: async (query) => {
          try {
            // Fetch External Data Source
            const dataUrl = `${el.dataset.url}?query=${query}`;
            const response = await fetch(dataUrl);
            const data = await response.json();
            return data;
          } catch (error) {
            return error;
          }
        },
      },
      resultsList: {
        class: 'autocomplete-component_results-list',
      }
    };

    const autoCompleteJS = new autoComplete(config);

    autoCompleteJS.input.addEventListener("selection", function (event) {
      const feedback = event.detail;
      // console.log(feedback);
      const selection = feedback.selection.value;
      autoCompleteJS.input.value = selection;
    });
  })
}
