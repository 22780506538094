// ref: https://gist.github.com/thomasvanholder/1778df30141e4c47362102518930c043
function getTimeRemaining(endtime) {
  let total = endtime - Date.now();
  if (total < 0) { total = 0; }

  const seconds = Math.floor((total / 1000) % 60);
  const minutes = Math.floor((total / 1000 / 60) % 60);
  const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
  const days = Math.floor(total / (1000 * 60 * 60 * 24));

  return { total, days, hours, minutes, seconds };
}

function updateClock(targets, finishesAt) {
  let timeRemaining = getTimeRemaining(finishesAt);

  if (targets.days) {
    targets.days.innerHTML = timeRemaining.days;
  }
  targets.hours.innerHTML = ("0" + timeRemaining.hours).slice(-2);
  targets.minutes.innerHTML = ("0" + timeRemaining.minutes).slice(-2);
  targets.seconds.innerHTML = ("0" + timeRemaining.seconds).slice(-2);

  return timeRemaining;
}

export default function (Alpine) {
  Alpine.directive('timer', (el, { expression }, { cleanup }) => {
    const finishesAt = new Date(expression * 1000);

    if (isNaN(finishesAt)) {
      console.error("x-timer: Missing finishesAt value. Please set x-timer=<value>.")
      console.error(`finishesAt: ${finishesAt}`)
      return false;
    }

    let targets = {
      days: el._x_refs['timer-days'],
      hours: el._x_refs['timer-hours'],
      minutes: el._x_refs['timer-minutes'],
      seconds: el._x_refs['timer-seconds'],
    }

    updateClock(targets, finishesAt);

    let timeinterval = setInterval(() => {
      let timeRemaining = updateClock(targets, finishesAt);
      if (timeRemaining.total <= 0) {
        clearInterval(timeinterval);
      }
    }, 1000);

    cleanup(() => {
      clearInterval(timeinterval);
    });
  })
}